import { useTranslation } from "react-i18next"
import Button from "../../../components/buttons/Button"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

interface Props {
    title?: string
    index?: number
    data: {
        key?: string
        value: string,
        hasLogo?: string
    }[]
    id?: {
        route: string, text: string
    }
    grid?: boolean
    noBg?: boolean
    financialCard?: boolean
    status?: string
    handleStatus?: any
    appId?: string
    extraTop?: boolean
}
const DistributorInfo = ({ title, data, id, grid, noBg, financialCard, status, handleStatus, appId, extraTop, index }: Props) => {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [statusName, setStatusName] = useState<string>("")

    useEffect(() => {

        if (status == "1") {
            setStatusName(t("pending"))
        } else if (status == "2") {
            setStatusName(t("inProgress"))
        } else if (status == "3") {
            setStatusName(t("accepted"))
        } else if (status == "4" || status === "5") {
            setStatusName(t("rejected"))
        }
    }, [status, i18n])

    return (
        <div className={`data-info ${grid && "grid"} ${noBg && "noBg"} ${financialCard && "financialCard"} ${extraTop && "extraTopSpace"}`}>
            {title && <div className={`d-space ${id && "margin"}`}>
                <h2>{title}</h2>
                {id && <Button text={id.text} onClick={() => navigate(id.route)} />}
            </div>}
            <div className="cards-container">
                {data.map((item, index) => (
                    <div className="card" key={index}>
                        {item.key && <h6>{item.key}</h6>}
                        {item.hasLogo ?
                            <div className="logo">
                                {item.hasLogo && <img src={item.hasLogo} alt="" />}
                                {financialCard ?
                                    <div className="sp_status_container">
                                        <p>{item.value}</p>
                                        {<div className={`status ${status != "2" && "normal-cursor"} ${status == "1" ? "pending" : status == "2" ? "inProgress" : status == "3" ? "accepted" : "rejected"} `}
                                            onClick={() => status != "3" && status != "5" && status != "4" && handleStatus(appId)}
                                        >{

                                                statusName && (statusName)
                                            }</div>}
                                    </div>
                                    : <p>{item.value}</p>}

                            </div> :
                            <p className="with-logo">{item.value}</p>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DistributorInfo;